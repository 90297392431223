<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in serviceList"
             :key=index>
          <div style="background-color: white;border-radius: 10px;  width: 361px;height: 338px;">
            <router-link :to="{path:'/ChuXing',query:{groupId:index+1}}">
              <div data-aos="fade-up" data-aos-delay="70">
                <div :class="`service ${serviceStyle} ${checkTextAlign}`">
                  <div class="inner">
                    <div class="thumbnail">
                      <img :src="service.image" alt="blog.title" style="min-width: 336px;min-height: 203px;padding-top: 10px;">
                    </div>
                    <div class="content" style="padding-top: 10px;">
                      <font class="title" style="color: #4C656B;font-size: 30px;">{{service.title}}
                        <!--                                <router-link to="/service-details" v-html="service.title"/>-->
<!--                        <div v-html="service.title" />-->

                      </font>
                      <p class="description b1 color-gray mb--0" v-html="service.description" style="color: #595959;font-size: 16px;text-align: left;" />
                    </div>
                    <!--                      <div class="image">
                                            <img :src="service.image" alt="Service Images"/>
                                          </div>-->
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServiceThreeSchemeApplication',
        props: {
            serviceStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        path: '/ChuXing',
                        image: require('@/assets/images/service/luLuChuXing.png'),
                        title: '陆路出行',
                        description: '为消费侧化石能源出行赋能城市交通的绿色发展提供解决方案'

                    },
                    {
                        path: '/ChuXing',
                        image: require('@/assets/images/service/hkcx.png'),
                        title: '航空出行',
                        description: '民用航空的消费者提供个人燃油碳中和解决方案'
                    },
                    {
                      path: '/ChuXing',
                        image: require('@/assets/images/service/ltsp.png'),
                        title: '零碳商品',
                        description: '为日用消费品的零碳化提供B2C的解决方案'
                    }
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>
