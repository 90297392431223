<template>
    <Layout :header-transparency="false" :show-top-header="false">
      <!--         Start Slider Area banner-->
<!--      <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
        <VueSlickCarousel v-bind="sliderSetting">
          <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
            &lt;!&ndash;               height-600  bg-overlay 覆盖top&ndash;&gt;
            <div class="  bg_image">
              &lt;!&ndash;                         :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`}"&ndash;&gt;

              <div class="container">
                <div class="row row&#45;&#45;30 align-items-center">
                  <div class="col-lg-12">
                    <div class="inner text-center" :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`,'background-size':'contain','background-repeat':'no-repeat','padding-top':'410px'}" style="background-size: contain;background-repeat: no-repeat;padding-top: 410px;">
                      <h1 class="title" v-html="slider.title"/>
                      <p class="description" v-html="slider.description"/>
                      &lt;!&ndash;                                        <div class="button-group mt&#45;&#45;30">
                                                                  <a class="btn-default btn-large round"
                                                                     href="#">
                                                                      Banner页面
                                                                  </a>
                                                              </div>&ndash;&gt;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>-->
      <!--         End Slider Area-->
      <div class="blog-area" >
        <div class="" style="text-align: center;">
          <div class="col-lg-12" >
            <img  :src="require(`@/assets/images/banner/top.png`)" />
          </div>
        </div>
      </div>

      <div class="breadcrumb-area " data-aos="fade-up" >

        <div class="container">

          <div class="row">

            <div class="col-lg-1"></div>

            <div class="col-lg-10">
              <div class="breadcrumb-inner" style="text-align: left">
                <!--                <h1 class="title theme-gradient h2" v-html="title" data-aos="fade-up"></h1>-->
                <ul class="page-list" data-aos-delay="50">
                  <!--                  <img :src="homepageIcon" style="position: fixed;bottom: 4px; color: white; width: 34px;height: 34px;" />-->
                  <router-link to="/"><img :src="homepageIcon" style="min-width: 34px;margin-top: -14px;" /></router-link>
                  <li class="rn-breadcrumb-item" style="color: #898D90;font-size: 24px;"><router-link to="/"> &nbsp;首页</router-link></li>
                  <li class="rn-breadcrumb-item active" style="font-size: 30px;color: #444444;opacity: 1;">&nbsp;联系我们</li>
                </ul>
              </div>
              <Separator style="margin-top: 10px;"/>
            </div>
          </div>
        </div>
      </div>

      <div breadcrumb-area data-aos="fade-up" style="margin-top: 68px;margin-bottom: 101px;">
        <div class="container">
<!--                    <div style="background-color: #f4f4f4;width: 1170px;height: 591px;text-align: center;">
-->
        <div class="row" >
          <div class="col-lg-1" ></div>
          <div class="col-lg-1" style="background-color: #f4f4f4;"></div>
          <div class="col-lg-8" style="background-color: #f4f4f4;padding: 38px !important;" >
            <h4 class="title w-700" style="font-size: 34px;margin-bottom: 68px;">联系方式</h4>
            <div class="row" v-for="(item, index) in contactDataList">
              <div class="col-lg-1" align="center" >
                <img :src="item.img"/>
              </div>
              <div class="col-lg-11">
                <p style="font-size: 30px;color:#444444;" >{{item.content}}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-1" style="background-color: #f4f4f4;"></div>
          <div class="col-lg-2"></div>
        </div>
        </div>
      </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Icon from "../components/icon/Icon";
    import GoogleMap from "../components/elements/contact/GoogleMap";
    import ContactForm from "../components/elements/contact/ContactForm";
    import VueSlickCarousel from "vue-slick-carousel";
    import Separator from "@/components/elements/separator/Separator";

    export default {
        name: 'ContactPage',
        components: { ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout,VueSlickCarousel,Separator},
        data() {
          return {
            homepageIcon: '',
            sliderSetting: {
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrows: true,
              responsive: [
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 1,
                  }
                },
                {
                  breakpoint: 993,
                  settings: {
                    slidesToShow: 1,
                  }
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 1,
                  }
                },
                {
                  breakpoint: 481,
                  settings: {
                    slidesToShow: 1,
                  }
                }
              ]
            },
            sliderData: [
              {
                image: 'banner1',
                title: ``,
                description: ``
              },
              {
                image: 'banner2',
                title: ``,
                description: ``
              }
            ],
            contactDataList: [
              {
                img: require('@/assets/images/icons/Mobile.png'),
                content:'客服电话：010-  82062615',
              }, {
                img: require('@/assets/images/icons/Mail.png'),
                content:'办公时间：09：00-18：00',
              }, {
                img: require('@/assets/images/icons/Website.png'),
                content:'商业沟通：miaolei@mycnb.cn',
              }, {
                img: require('@/assets/images/icons/Username.png'),
                content:'办公地址：北京市朝阳区水岸南街中合国青大厦6层',
              }
            ]
          }
        },
      created() {
        this.getImagePath();
      },
      methods: {
            getImagePath() {
              this.homepageIcon = require(`@/assets/images/icons/home.png`)
            }
        }
    }
</script>
<style scoped>


.breadcrumb-inner ul.page-list li::after {
  position: absolute;
  content: ">";
  top: 0;
  transform: translateY(5%);
  background-color: var(--color-body);
  width: 0px;
  height: 5px;
  border-radius: 1%;
  opacity: inherit;
  right: 3px;
}
</style>
