<template>
    <ul :class="[
            `brand-list`,
            {'brand-style-1': brandStyle === 1},
            {'brand-style-2': brandStyle === 2},
            {'brand-style-2': brandStyle === 3},
            {'brand-style-2 variation-2': brandStyle === 4},
        ]">
        <li v-for="(brand, index) in brandList" :key="index" :style="{'min-width': brand.width, height:brand.height,'max-width':brand.width,'margin-left': brand.marginleft}">
<!--            <a href="#"><img :src="brand.image" alt="Brand Image"/></a>-->
<!--          style="min-height: 122px; min-width: 173px;" ,'height':'${brand.height}}'-->
            <img class="w-100" :src="brand.image" alt="Brand Image"    />
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'Brand',
        props: {
            brandList: {},
            brandStyle: {
                type: Number,
                default: 1
            }
        }
    }
</script>


<style scoped>

ul li{
  width: 171px;

  height: 160px;

  border: 0px solid #d3d3d3;
  text-align: center;
  line-height: 160px;
}
ul li img{
  display: inline-block;
  vertical-align:middle ;
}
</style>
