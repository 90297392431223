<template>
    <ul class="mainmenu center" style="min-height: 100px;text-align: center;">

        <li @click="selectChange(1)" ><router-link to="/Startup">首页</router-link></li>
        <li @click="selectChange(2)" ><router-link to="/about">关于我们</router-link></li>
        <li @click="selectChange(3)" ><router-link to="/SchemeApplication">方案应用</router-link></li>
        <li @click="selectChange(4)" ><router-link to="/news">公司新闻</router-link></li>
        <li @click="selectChange(5)" ><router-link to="/contact">联系我们</router-link></li>
<!--      <li class="has-droupdown has-menu-child-item">
        <a href="#">
           实例
          <Icon name="plus" class="collapse-icon plus"/>
          <Icon name="minus" class="collapse-icon minus"/>
        </a>
        <ul class="submenu">
          <li><router-link to="/business-consulting">Business Consulting</router-link></li>
          <li><router-link to="/business-consulting-2">Business Consulting 02</router-link></li>
          <li><router-link to="/corporate">Corporate</router-link></li>
          <li><router-link to="/business">Business</router-link></li>
          <li><router-link to="/digital-agency">Digital Agency</router-link></li>
          <li><router-link to="/finance">Finance</router-link></li>
          <li><router-link to="/company">Company</router-link></li>
          <li><router-link to="/marketing-agency">Marketing Agency</router-link></li>
          <li><router-link to="/travel-agency">Travel Agency</router-link></li>
          <li><router-link to="/consulting">Consulting</router-link></li>
          <li><router-link to="/seo-agency">SEO Agency</router-link></li>
          <li><router-link to="/personal-portfolio">Personal Portfolio</router-link></li>
          <li><router-link to="/event-conference">Event Conference</router-link></li>
          <li><router-link to="/creative-portfolio">Creative Portfolio</router-link></li>
          <li><router-link to="/freelancer">Freelancer</router-link></li>
          <li><router-link to="/international-consulting">International Consulting</router-link></li>
          <li><router-link to="/startup">Startup</router-link></li>
          <li><router-link to="/web-agency">Web Agency</router-link></li>
        </ul>
      </li>-->

<!--        <li class="with-megamenu has-menu-child-item">
            <a href="#">
                Elements
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
            <div class="rn-megamenu">
                <div class="wrapper">
                    <div class="row row--0">
                        <div class="col-lg-3 single-mega-item">
                            <ul class="mega-menu-item">
                                <li><router-link to="/button">Button</router-link></li>
                                <li><router-link to="/service">Service</router-link></li>
                                <li><router-link to="/counter">Counter</router-link></li>
                                <li><router-link to="/progressbar">Progressbar</router-link></li>
                                <li><router-link to="/accordion">Accordion</router-link></li>
                                <li><router-link to="/social-share">Social Share</router-link></li>
                                <li><router-link to="/blog-grid">Blog Grid</router-link></li>
                            </ul>
                        </div>
                        <div class="col-lg-3 single-mega-item">
                            <ul class="mega-menu-item">
                                <li><router-link to="/team">Team</router-link></li>
                                <li><router-link to="/portfolio">Portfolio</router-link></li>
                                <li><router-link to="/testimonial">Testimonial</router-link></li>
                                <li><router-link to="/timeline">Timeline</router-link></li>
                                <li><router-link to="/tab">Tab</router-link></li>
                                <li><router-link to="/pricing">Pricing</router-link></li>
                                <li><router-link to="/spit-section">Split Section</router-link></li>
                            </ul>
                        </div>
                        <div class="col-lg-3 single-mega-item">
                            <ul class="mega-menu-item">
                                <li><router-link to="/call-to-action">Call To Action</router-link></li>
                                <li><router-link to="/video">Video</router-link></li>
                                <li><router-link to="/gallery">Gallery</router-link></li>
                                <li><router-link to="/contact">Contact</router-link></li>
                                <li><router-link to="/brand">Brand</router-link></li>
                                <li><router-link to="/portfolio">Portfolio</router-link></li>
                                <li><router-link to="/error">404</router-link></li>
                            </ul>
                        </div>
                        <div class="col-lg-3 single-mega-item">
                            <ul class="mega-menu-item">
                                <li>
                                    <router-link to="/advance-tab">
                                        Advance Tab <span class="rn-badge-card">Hot</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="#">
                                        Brand Carousel <span class="rn-badge-card">Comming</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/advance-pricing">
                                        Advance Pricing <span class="rn-badge-card">Hot</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/portfolio-details/1">
                                        Portfolio Details
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/blog-details/1">
                                        Blog Details
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="has-droupdown has-menu-child-item">
            <a href="#">
                Blog
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
            <ul class="submenu">
                <li><router-link to="/blog-grid">Blog Grid</router-link></li>
                <li><router-link to="/blog-grid-sidebar">Blog Grid Sidebar</router-link></li>
                <li><router-link to="/blog-list">Blog List View</router-link></li>
                <li><router-link to="/blog-list-sidebar">Blog List View Sidebar</router-link></li>
            </ul>
        </li>
        <li class="has-droupdown has-menu-child-item">
            <a href="#">
                Portfolio
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
            <ul class="submenu">
                <li><router-link to="/portfolio">Portfolio Default</router-link></li>
                <li><router-link to="/portfolio-three-column">Portfolio Three Column</router-link></li>
                <li><router-link to="/portfolio-full-width">Portfolio Full Width</router-link></li>
                <li><router-link to="/portfolio-grid-layout">Portfolio Grid Layout</router-link></li>
                <li><router-link to="/portfolio-box-layout">Portfolio Box Layout</router-link></li>
            </ul>
        </li>
        <li><router-link to="/contact">Contact</router-link></li>-->
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        beforeDestroy() {
         // localStorage.setItem('currentSelectedIndex', 0)
        },
        components: {Icon},
        data() {
          return {
            currentSelectedIndex:0
          }
        },
      created() {
        this.inint()
      },
      methods: {
          inint() {
            setTimeout(()=>{
              var childElement = document.getElementsByClassName('router-link-exact-active'); // 选择到子元素
              childElement[0].parentElement.classList.add('currentSelected')
            }, 200)


          },
          selectChange(index) {
            var childElement = document.getElementsByClassName('router-link-exact-active'); // 选择到子元素
            childElement[0].parentElement.classList.add('currentSelected')
          }
        }
    }
</script>
<style scoped>
/*下划线跟随*/
/*ul{
  display: flex;
  position: initial;
}*/
.router-link-active{
  color: #00c390;
}
li a{
  color: #444444;
}
li{
  /*position: relative;
  padding: 1em 2em;
  font-size: 20px;
  list-style: none;
  white-space:nowrap;
  width: 25%;
  text-align: center;
  */
  height: 70px;
  color: #00c390;
}
li.current_li{
  list-style-type:none;
  border-bottom:2px solid #00c390;
  padding-bottom: 2px;
}
a:active, a:hover, a.current {
  color: #00c390;
}
a:hover{
  color: #00c390;
}
li.currentSelected:after{
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 3.5px;
  color: #00c390;
  transition: .5s all linear;
  background-color: #00c390;
}
li.currentSelected::after{
  width: 40%;
  color: #00c390;
}
li.currentSelected::after{
  left: 30%;     /*选中项上一个下划线收回的方向，从左往右收线*/
}
li:hover::after{
  /*left: 0;      !*选中项下划线出线的方向，从左往右出线*!*/
}
/*li:hover ~ li::after {
  !*left: 0;    !*选中项下一个下划线出线的方向，从左往右收线*!*!
}*/



</style>
