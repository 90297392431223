<template>
  <Layout :header-transparency="false" :show-top-header="false">
    <!-- Start Slider Area -->
    <div class="slider-area slider-style-8 " style="margin-top:68px;" data-aos="fade-up">
      <div class="single-slide">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="inner text-center">
                <!--                class="title"-->
                <div class="row">
                  <div class="col-lg-1"></div>
                  <div class="col-lg-10">
                    <h3  data-aos="fade-up" data-aos-delay="150">
                      <div class=" " data-aos="fade-up" data-aos-delay="100" style="color: #8C0202;font-size: 24px;">{{newdata.title}}</div>
                    </h3>
                    <div class="row" data-aos="fade-up">
                      <div class="col-lg-8" style=" color: #323232;font-size: 14px;margin-bottom: 10px;">
                        <div style="">发布日期: {{newdata.yearMonth}}-{{newdata.day}}</div>

                      </div>
                      <div class="col-lg-2" style=" color: #323232;font-size: 14px;margin-bottom: 10px;margin-left: -60px;">
                        <div style="">发布人: 汇众利合</div>
                      </div>
                    </div>
                    <!--                    <p data-aos="fade-up" style=" color: #323232;font-size: 14px">发布日期: {{newdata.startDate}}</p>-->
                    <Separator data-aos="fade-up"/>
                    <p class="description left" align="left" data-aos="fade-up" data-aos-delay="100" style="color:#000000;font-size: 18px;">
                      {{newdata.content}}

                    </p>
                    <p v-if="newdata.content2!=null && newdata.content2.length >0" class="description left" align="left" data-aos="fade-up" data-aos-delay="100" style="color:#000000;font-size: 18px;">
                      {{newdata.content2}}

                    </p>
                  </div>
                  <div class="col-lg-1"></div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Counterup Area -->
    <div v-if="newdata.img2 != null && newdata.img2.length > 0" class="our-counterup-area" data-aos="fade-up" >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="thumbnail" data-aos="fade-up" align="center" >
              <img class="radius w-50 " :src="newdata.img2" alt=""  />
            </div>
          </div>

        </div>

      </div>
    </div>

    <div slider-area  data-aos="fade-up" style="margin-top:38px;">
      <div class="single-slide">
        <div class="container">
          <div class="row">
            <div class="col-lg-2">

            </div>
            <div class="col-lg-8">
              <p v-if="newdata.content3 != null && newdata.content3.length > 0" class="description left" align="left" data-aos="fade-up" data-aos-delay="100" style="color:#000000;font-size: 18px;">
                {{newdata.content3}}
              </p>
            </div>
            <div class="col-lg-2">

            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="newdata.img3 != null && newdata.img3.length > 0" class="our-counterup-area" data-aos="fade-up" >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="thumbnail" data-aos="fade-up" align="center" >
              <img class="radius w-50 " :src="newdata.img3" alt=""  />
            </div>
          </div>

        </div>

      </div>
    </div>



    <div slider-area  data-aos="fade-up" style="margin-top:38px;">
      <div class="single-slide">
        <div class="container">
          <div class="row">
            <div class="col-lg-2">

            </div>
            <div class="col-lg-8">
              <p v-if="newdata.content4 != null && newdata.content4.length > 0" class="description left" align="left" data-aos="fade-up" data-aos-delay="100" style="color:#000000;font-size: 18px;">
                {{newdata.content4}}
              </p>
            </div>
            <div class="col-lg-2">

            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="newdata.img4 != null && newdata.img4.length > 0" class="our-counterup-area" data-aos="fade-up" >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="thumbnail" data-aos="fade-up" align="center" >
              <img class="radius w-50 " :src="newdata.img4" alt=""  />
            </div>
          </div>

        </div>

      </div>
    </div>



    <div slider-area  data-aos="fade-up" style="margin-top:38px;">
      <div class="single-slide">
        <div class="container">
          <div class="row">
            <div class="col-lg-2">

            </div>
            <div class="col-lg-8">
              <p v-if="newdata.content5 != null && newdata.content5.length > 0" class="description left" align="left" data-aos="fade-up" data-aos-delay="100" style="color:#000000;font-size: 18px;">
                {{newdata.content5}}
              </p>
            </div>
            <div class="col-lg-2">

            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="newdata.img5 != null && newdata.img5.length > 0" class="our-counterup-area" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="thumbnail" data-aos="fade-up" align="center" >
              <img class="radius w-50 " :src="newdata.img5" alt=""  />
            </div>
          </div>

        </div>

      </div>
    </div>


    <!-- 翻页 -->
    <div class="slider-area slider-style-8 " style="margin-top:8px;" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-lg-10">
                <Separator style="margin-bottom:15px;" />
                <a  :class="lastBtnClass" @click="changeType(lastSn)" @mouseenter="zoomIn(1)" @mouseleave="zoomOut(1)" style="font-size: 14px;"><img :src="lastBtnImg" style="width: 20px;height: 20px;margin-left: 5px;" /> 上一篇{{lastText}} </a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-lg-10">
                <a :class="nextBtnClass" @click="changeType(nextSn)" @mouseenter="zoomIn(2)" @mouseleave="zoomOut(2)" style="font-size: 14px;"><img :src="nextBtnImg" style="width: 20px;height: 20px;margin-left: 5px;" /> 下一篇{{nextText}} </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="slider-area slider-style-8 " style="margin-top:38px;">
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";
import VueSlickCarousel from "vue-slick-carousel";
import Separator from "@/components/elements/separator/Separator";
import ServiceThreeSchemeApplication from "@/components/elements/service/ServiceThreeSchemeApplication";
import internationalConsulting from "@/views/home-pages/InternationalConsulting";
import Button from "@/components/elements/button/Button";

export default {
  name: 'NewsDetail',
  components: {Button, ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout,VueSlickCarousel,Separator,ServiceThreeSchemeApplication},
  data() {
    return {
      currntSn:0,
      chuXingDataList:null,
      newdata: null,
      lastText:'',
      nextText:'',
      homepageIcon: '',
      lastBtnImg:  require(`@/assets/images/icons/shang.png`),
      nextBtnImg:  require(`@/assets/images/icons/shang.png`),
      shang:  require(`@/assets/images/icons/shang.png`),
      xia:  require(`@/assets/images/icons/xia.png`),
      lastBtnClass: {
        lastClass: true,
        lastClassSelected: false
      },
      nextBtnClass: {
        nextClass: true,
        nextClassSelected: false,
      },
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
      sliderData: [
        {
          image: 'banner1',
          title: ``,
          description: ``
        },
        {
          image: 'banner2',
          title: ``,
          description: ``
        }
      ],
      lastSn:0,
      nextSn:2,

    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.homepageIcon = require(`@/assets/images/icons/home.png`)
      this.chuXingDataList = []
      for (const item of JSON.parse(localStorage.getItem('newsDataList'))) {
          this.chuXingDataList.push(item)
      }
      const sn =  parseInt(this.$route.query.sn)
      this.currntSn = sn
      this.newdata = this.chuXingDataList[sn]
      this.lastSn = sn-1;
      if(this.lastSn < 0){
        this.lastSn = 0
        this.lastText = ''
      }
      else {
        this.lastText = "：" + this.chuXingDataList[this.lastSn].title
      }
      this.nextSn = sn+1;
      if(this.nextSn >= this.chuXingDataList.length) {
        this.nextSn = this.chuXingDataList.length-1
        this.nextText = ""
      }
      else {
        this.nextText = "：" + this.chuXingDataList[this.nextSn].title
      }
    },
    changeType(sn) {
      if(sn == this.currntSn) return
      this.newdata = this.chuXingDataList[sn]
      this.$route.query.sn = sn
      this.init()
      this.scrollToTop()
      window.scrollTo(0,0)
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      /*  document.body.scrollTop = 0; // firefox
        document.documentElement.scrollTop = 0; // safari*/
    },
    zoomIn(index) {
      if(index==1) {
        this.lastBtnImg = this.xia
        // 图片切换为红色
        this.lastBtnClass.lastClass = false;
        this.lastBtnClass.lastClassSelected = true;
      }  else if(index==2) {
        this.nextBtnImg = this.xia
        this.nextBtnClass.nextClass = false;
        this.nextBtnClass.nextClassSelected = true;
      }
    },
    zoomOut(index) {
      // 图片切换为黑色
      if(index==1){
        this.lastBtnImg = this.shang
        this.lastBtnClass.lastClass = true;
        this.lastBtnClass.lastClassSelected = false;
      }
      else if(index==2) {
        this.nextBtnImg = this.shang
        this.nextBtnClass.nextClass = true;
        this.nextBtnClass.nextClassSelected = false;
      }
    },
  }
}
</script>
<style scoped>


.breadcrumb-inner ul.page-list li::after {
  position: absolute;
  content: ">";
  top: 0;
  transform: translateY(5%);
  background-color: var(--color-body);
  width: 0px;
  height: 5px;
  border-radius: 1%;
  opacity: inherit;
  right: 3px;
}
.lastClass>img {

}
a:hover{
  color:#8C0202;
}
.lastClassSelected>img {
  transform: rotate(180deg);
}
.nextClass>img {
  transform: rotate(180deg)
}
.nextClassSelected>img {
  /*transform: rotate(-180deg);*/
}
a {
  cursor: pointer; /* 将鼠标移动到<a>标签上时显示为手型光标 */
}

p {
  text-indent: 2em; /* 这里的"-2em"表示向左移动两个字符的宽度 */
  white-space: pre-line;
}
</style>
