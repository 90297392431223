<template>
  <Layout :header-transparency="false" :show-top-header="false">
    <!--         Start Slider Area banner-->
<!--    <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">-->
<!--      <VueSlickCarousel v-bind="sliderSetting">-->
<!--        <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">-->
<!--          &lt;!&ndash;               height-600  bg-overlay 覆盖top&ndash;&gt;-->
<!--          <div class="  bg_image">-->
<!--            &lt;!&ndash;                         :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`}"&ndash;&gt;-->

<!--            <div class="container">-->
<!--              <div class="row row&#45;&#45;30 align-items-center">-->
<!--                <div class="col-lg-12">-->
<!--                  <div class="inner text-center" :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`,'background-size':'contain','background-repeat':'no-repeat','padding-top':'410px'}" style="background-size: contain;background-repeat: no-repeat;padding-top: 410px;">-->
<!--                    <h1 class="title" v-html="slider.title"/>-->
<!--                    <p class="description" v-html="slider.description"/>-->
<!--                    &lt;!&ndash;                                        <div class="button-group mt&#45;&#45;30">-->
<!--                                                                <a class="btn-default btn-large round"-->
<!--                                                                   href="#">-->
<!--                                                                    Banner页面-->
<!--                                                                </a>-->
<!--                                                            </div>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </VueSlickCarousel>-->
<!--    </div>-->
    <div class="blog-area" >
      <div class="" style="text-align: center;">
        <div class="col-lg-12" >
          <img  :src="require(`@/assets/images/banner/top.png`)" />
        </div>
      </div>
    </div>
    <!--         End Slider Area-->
    <div class="breadcrumb-area " data-aos="fade-up" >

      <div class="container">

        <div class="row">

          <div class="col-lg-1"></div>

          <div class="col-lg-10">
            <div class="breadcrumb-inner" style="text-align: left">
              <ul class="page-list" data-aos-delay="50">
                <router-link to="/"><img :src="homepageIcon" style="min-width: 34px;margin-top: -14px;" /></router-link>
                <li class="rn-breadcrumb-item" style="color: #898D90;font-size: 24px;"><router-link to="/"> &nbsp;&nbsp;首页</router-link></li>
                <li class="rn-breadcrumb-item active" style="font-size: 30px;color: #444444;opacity: 1;">&nbsp;关于我们</li>
              </ul>
            </div>
            <Separator style="margin-top: 10px;min-width: 1139px;"/>
          </div>
        </div>
      </div>
    </div>


    <div breadcrumb-area data-aos="fade-up" style="margin-top: 68px;margin-bottom: 101px;">
      <div class="container">
        <div class="row" >
          <div class="col-lg-1"></div>
          <div class="col-lg-4" >
            <img class="w-100" :src="AboutImg1" alt="" style="min-width: 525px;">
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-5" style="margin-left: -42px;padding: 0px 50px 0px 10px;">
            <div class="content">
              <div class="" style="color: #1E1E1E;font-size: 32px;">
                公司介绍
              </div>
              <p class="descriptiion" style="color: #717171;font-size: 24px;">
                国内首家提供消费侧碳中和数字化溯源体系，服务消费侧碳中和的专业化科技创新公司。通过为消费侧碳中和提供数字化解决方案，赋能传统化石能源行业的绿色发展，缓解短期内不可避免的燃油碳排放问题，助力交通出行领域双碳目标的实现。
              </p>
            </div>
          </div>
<!--          <div class="col-lg-1"></div>-->
        </div>
      </div>
    </div>
    <div breadcrumb-area data-aos="fade-up" style="margin-top: 68px;margin-bottom: 101px;">
      <div class="container">
        <div class="row" >
          <div class="col-lg-1"></div>
          <div class="col-lg-5"  >
<!--          style="margin-left: -12px;padding: 0px 70px 0px 10px;"-->
            <div class="content" style="padding: 0px 65px 0px 10px;">
              <div class="" style="color: #1E1E1E;font-size: 32px;">
                发展理念
              </div>
              <p class="descriptiion" style="color: #717171;font-size: 24px;">
                打造“政府 (G) 引导、企业 (B) 实践、消费者(C) 参与”的GBC模式。发挥市场机制，积极鼓励消费者参与消费侧碳中和活动，助力企业践行社会责任，提升消费者的环保意识。建立数字化的碳中和溯源查证体系，为消费侧碳中和持续发展保驾护航。

              </p>
            </div>
          </div>
          <div class="col-lg-1"></div>
<!--          style="margin-left: -52px;padding: 0px 70px 0px 10px;"-->
          <div class="col-lg-5">
            <img class="w-100" :src="AboutImg2" alt="" style="min-width: 525px;margin-left: -58px;">
          </div>

<!--          <div class="col-lg-1"></div>-->
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";
import VueSlickCarousel from "vue-slick-carousel";
import Separator from "@/components/elements/separator/Separator";

export default {
  name: 'ContactPage',
  components: { ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout,VueSlickCarousel,Separator},
  data() {
    return {
      homepageIcon: '',
      AboutImg1: '',
      AboutImg2: '',
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
      sliderData: [
        {
          image: 'banner1',
          title: ``,
          description: ``
        },
        {
          image: 'banner2',
          title: ``,
          description: ``
        }
      ]

    }
  },
  created() {
    this.getImagePath();
  },
  methods: {
    getImagePath() {
      this.homepageIcon = require(`@/assets/images/icons/home.png`)
      this.AboutImg1 = require(`@/assets/images/about/AboutImg2.png`)
      this.AboutImg2 = require(`@/assets/images/about/AboutImg1.png`)
    }
  }
}
</script>
<style scoped>


.breadcrumb-inner ul.page-list li::after {
  position: absolute;
  content: ">";
  top: 0;
  transform: translateY(5%);
  background-color: var(--color-body);
  width: 0px;
  height: 0px;
  border-radius: 1%;
  opacity: inherit;
  right: 7px;
}
</style>
