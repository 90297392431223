<template>
  <Layout :header-transparency="false" :show-top-header="false">
    <!--         Start Slider Area banner-->
<!--    <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
      <VueSlickCarousel v-bind="sliderSetting">
        <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
          &lt;!&ndash;               height-600  bg-overlay 覆盖top&ndash;&gt;
          <div class="  bg_image">
            &lt;!&ndash;                         :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`}"&ndash;&gt;

            <div class="container">
              <div class="row row&#45;&#45;30 align-items-center">
                <div class="col-lg-12">
                  <div class="inner text-center" :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`,'background-size':'contain','background-repeat':'no-repeat','padding-top':'410px'}" style="background-size: contain;background-repeat: no-repeat;padding-top: 410px;">
                    <h1 class="title" v-html="slider.title"/>
                    <p class="description" v-html="slider.description"/>
                    &lt;!&ndash;                                        <div class="button-group mt&#45;&#45;30">
                                                                <a class="btn-default btn-large round"
                                                                   href="#">
                                                                    Banner页面
                                                                </a>
                                                            </div>&ndash;&gt;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>-->
    <!--         End Slider Area-->
    <div class="blog-area" >
      <div class="" style="text-align: center;">
        <div class="col-lg-12" >
          <img  :src="require(`@/assets/images/banner/top.png`)" />
        </div>
      </div>
    </div>
    <!--         End Slider Area-->
    <div class="breadcrumb-area " data-aos="fade-up" >

      <div class="container">

        <div class="row">

          <div class="col-lg-1"></div>

          <div class="col-lg-10">
            <div class="breadcrumb-inner" style="text-align: left">
              <!--                <h1 class="title theme-gradient h2" v-html="title" data-aos="fade-up"></h1>-->
              <ul class="page-list" data-aos-delay="50">
                <!--                  <img :src="homepageIcon" style="position: fixed;bottom: 4px; color: white; width: 34px;height: 34px;" />-->
                <router-link to="/"><img :src="homepageIcon" style="min-width: 34px;margin-top: -14px;" /></router-link>
                <li class="rn-breadcrumb-item" style="color: #898D90;font-size: 24px;"><router-link to="/"> &nbsp;首页</router-link></li>
                <li class="rn-breadcrumb-item active" style="font-size: 30px;color: #444444;opacity: 1;">&nbsp;方案应用</li>
              </ul>
            </div>
            <Separator style="margin-top: 10px;"/>
          </div>
        </div>
      </div>
    </div>


    <div class="container"  >
    <div style="background-color: #F7FBFC;width:1327px;height: 662px;position: absolute; margin-top: 68px;background-position: center center;" >
      &nbsp;
    </div>
    </div>
    <!--      rn-section-gap  间隔-->
    <!--      :style="{'background-image': `url(${require(`@/assets/images/bg/jiejue_bj.png`)})`,'background-size':'contain','background-repeat':'no-repeat','padding-top':'0px','background-position-x':'center','background-position-y':'center'}"-->
    <div class="blog-area"  style="margin-top: 68px;" data-aos="fade-up" >

      <div class="container" >

        <div class="row" >


          <div class="col-lg-1"></div>
          <div class="col-lg-10" style="margin-top: 68px;">

            <h3 class="title " style="font-size: 40px;color: #294147;">&nbsp;赋能企业双碳目标的实现，助力消费者社会责任的提升</h3>
            <p class="description b1" style="font-size:24px;color: #595959;" >&nbsp;用科技汇聚平凡的力量、用数据呈现平凡的伟大</p>
          </div>
          <div class="col-lg-1"></div>

        </div>

        <div class="row row--15">
          <div class="col-lg-1"></div>

            <div class="col-lg-10">
              <ServiceThreeSchemeApplication
                  service-style="service__style--2"
                  text-align="center"
              />
             </div>
          <div class="col-lg-1"></div>

        </div>
      </div>
    </div>
    <!-- End Blog Area -->
    <div class="blog-area"  style="margin-top: 200px;" data-aos="fade-up" >
    </div>



  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";
import VueSlickCarousel from "vue-slick-carousel";
import Separator from "@/components/elements/separator/Separator";
import ServiceThreeSchemeApplication from "@/components/elements/service/ServiceThreeSchemeApplication";

export default {
  name: 'ContactPage',
  components: { ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout,VueSlickCarousel,Separator,ServiceThreeSchemeApplication},
  data() {
    return {
      homepageIcon: '',
      AboutImg1: '',
      AboutImg2: '',
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
      sliderData: [
        {
          image: 'banner1',
          title: ``,
          description: ``
        },
        {
          image: 'banner2',
          title: ``,
          description: ``
        }
      ]

    }
  },
  created() {
    this.getImagePath();
  },
  methods: {
    getImagePath() {
      this.homepageIcon = require(`@/assets/images/icons/home.png`)
      this.AboutImg1 = require(`@/assets/images/about/AboutImg1.png`)
      this.AboutImg2 = require(`@/assets/images/about/AboutImg2.png`)
    }
  }
}
</script>
<style scoped>


.breadcrumb-inner ul.page-list li::after {
  position: absolute;
  content: ">";
  top: 0;
  transform: translateY(5%);
  background-color: var(--color-body);
  width: 0px;
  height: 5px;
  border-radius: 1%;
  opacity: inherit;
  right: 3px;
 /* background-repeat: no-repeat;
  background-size: cover; !* 确保背景图片完全覆盖元素 *!
  background-position: center center;*/
}
</style>
