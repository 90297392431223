<template>
  <Layout :header-transparency="false" :show-top-header="false">
    <!--         Start Slider Area banner-->
<!--    <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
      <VueSlickCarousel v-bind="sliderSetting">
        <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
          &lt;!&ndash;               height-600  bg-overlay 覆盖top&ndash;&gt;
          <div class="  bg_image">
            &lt;!&ndash;                         :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`}"&ndash;&gt;

            <div class="container">
              <div class="row row&#45;&#45;30 align-items-center">
                <div class="col-lg-12">
                  <div class="inner text-center" :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`,'background-size':'contain','background-repeat':'no-repeat','padding-top':'410px'}" style="background-size: contain;background-repeat: no-repeat;padding-top: 410px;">
                    <h1 class="title" v-html="slider.title"/>
                    <p class="description" v-html="slider.description"/>
                    &lt;!&ndash;                                        <div class="button-group mt&#45;&#45;30">
                                                                <a class="btn-default btn-large round"
                                                                   href="#">
                                                                    Banner页面
                                                                </a>
                                                            </div>&ndash;&gt;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>-->
    <!--         End Slider Area-->
    <div class="blog-area" >
      <div class="" style="text-align: center;">
        <div class="col-lg-12" >
          <img  :src="require(`@/assets/images/banner/top.png`)" />
        </div>
      </div>
    </div>


    <div class="breadcrumb-area " data-aos="fade-up" >

      <div class="container">

        <div class="row">

          <div class="col-lg-1"></div>

          <div class="col-lg-10">
            <div class="breadcrumb-inner" style="text-align: left">
              <!--                <h1 class="title theme-gradient h2" v-html="title" data-aos="fade-up"></h1>-->
              <ul class="page-list" data-aos-delay="50">
                <!--                  <img :src="homepageIcon" style="position: fixed;bottom: 4px; color: white; width: 34px;height: 34px;" />-->
                <router-link to="/"><img :src="homepageIcon" style="min-width: 34px;margin-top: -14px;" /></router-link>
                <li class="rn-breadcrumb-item" style="color: #898D90;font-size: 24px;"><router-link to="/"> &nbsp;首页</router-link></li>
                <li class="rn-breadcrumb-item active" style="font-size: 30px;color: #444444;opacity: 1;">&nbsp;公司新闻</li>
              </ul>
            </div>
            <Separator style="margin-top: 10px;"/>
          </div>
        </div>
      </div>
    </div>



    <!--      rn-section-gap  间隔-->
    <!--      :style="{'background-image': `url(${require(`@/assets/images/bg/jiejue_bj.png`)})`,'background-size':'contain','background-repeat':'no-repeat','padding-top':'0px','background-position-x':'center','background-position-y':'center'}"-->
    <div class="blog-area"  style="margin-top: 58px;" data-aos="fade-up" >
      <div class="container">
        <div class="row" style="margin-top: 30px;" v-for="(item, index) in newsDataList">

            <div class="col-lg-1"></div>

            <div class="col-lg-10" >
              <div class="row">
<!--                style="padding-left: 20px;"-->
                <div class="col-lg-1 container  " style=" display: flex;justify-content: center;align-items: center;"   >
                  <div class="text-center center" style="background-color: #00C390; color: white;width: 80px;height: 88px;font-size: 40px;color: #FFFFFF;display: flex;justify-content: center;align-items: center;">
<!--                    <p class="text-center" style="font-size: 40px;color: #FFFFFF;">{{ item.showSn }}</p>-->
                    {{ item.showSn }}
<!--
                    <div style="font-size: 40px;color: #FFFFFF;">{{ item.day }}</div>
                    <div  style="font-size: 16px;color: #FFFFFF;">{{item.yearMonth}}</div>
-->
                  </div>
                </div>
                <div class="col-lg-11" >
                  <router-link :to="{path:'/NewsDetail',query:{sn:item.sn-1}}">
                  <font class="title " style="font-size: 24px;color: #444444;">{{item.title}}</font>
                  </router-link>
                  <router-link :to="{path:'/NewsDetail',query:{sn:item.sn-1}}">
                  <div class="description b1 textSytle" style="font-size: 18px;color: #999999;"  >{{item.subtitle}}</div>
                  </router-link>
                </div>
                <Separator style="margin-top: 10px;"/>

              </div>


            </div>
            <div class="col-lg-2"></div>
        </div>

      </div>
    </div>
    <!-- End Blog Area -->
    <div class="blog-area"  style="margin-top: 127px;" data-aos="fade-up" >
    </div>



  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";
import VueSlickCarousel from "vue-slick-carousel";
import Separator from "@/components/elements/separator/Separator";
import ServiceThreeSchemeApplication from "@/components/elements/service/ServiceThreeSchemeApplication";

export default {
  name: 'News',
  components: { ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout,VueSlickCarousel,Separator,ServiceThreeSchemeApplication},
  data() {
    return {
      newsDataList: [
        {
        sn:1,
        showSn:'05',
        groupId:1,
        path:'NewsDetail',
        yearMonth: '2022-11',
        day:'04',
        title:'CNB（碳中盒）为加油站提供燃油碳中和数字化解决方案',
        subtitle:'2022年11月4日，以“助力碳中和 让地球更绿色”为主题的碳中和消费服务活动在北京地区的中国石油加油站试点上线，标志着中国石油成为行业内首家为车主用户提供化石能源个人消费领域碳中和服务的成品油零售企业（摘抄中国石油网）。该活动由北京汇众利合信息技术咨询有限公司提供解决方案，通过CNB（碳中盒）系统为参加活动的燃油车主提供燃油碳中和服务，并为车主颁发由中国国检集团出具的“碳中和核查声明”电子证书，通过加油站助力燃油车主提高践行双碳意识。',
        content: '2022年11月4日，以“助力碳中和 让地球更绿色”为主题的碳中和消费服务活动在北京地区的中国石油加油站试点上线，标志着中国石油成为行业内首家为车主用户提供化石能源个人消费领域碳中和服务的成品油零售企业（摘抄中国石油网）。该活动由北京汇众利合信息技术咨询有限公司提供解决方案，通过CNB（碳中盒）系统为参加活动的燃油车主提供燃油碳中和服务，并为车主颁发由中国国检集团出具的“碳中和核查声明”电子证书，通过加油站助力燃油车主提高践行双碳意识。',
        content2:'参与活动车主获得证书',
        content3: '',
        content4: '',
        img1: require(`@/assets/images/news/4-1.jpg`),
        img2: require(`@/assets/images/news/4-1.jpg`),
        img3: null
      },
        {
          sn:2,
          showSn:'04',
          groupId:1,
          path:'NewsDetail',
          yearMonth: '2022-10',
          day:'28',
          title:'北京汇众利合以实际行动助力浙江碳普惠活动',
          subtitle:'10月28日，北京汇众利合信息技术咨询有限公司赞助的武夷山零碳矿泉水参与了由浙江省发改委、浙江省经济信息中心、菜鸟物流联合主办的“浙江碳普惠绿色校园行”活动。省发改委环资处处长陆琼、省经济信息中心能环部主任黄炜、浙能碳资产公司副总经理吴楠、菜鸟ESG副总裁牛智敬菜鸟绿色物流总经理王昊苏等出席活动。北京汇众利合信息技术咨询有限公司作为消费侧碳中和的发起者和倡议者，以实际行动践行国家碳普惠的推广和宣传。',
          content: '10月28日，北京汇众利合信息技术咨询有限公司赞助的武夷山零碳矿泉水参与了由浙江省发改委、浙江省经济信息中心、菜鸟物流联合主办的“浙江碳普惠绿色校园行”活动。省发改委环资处处长陆琼、省经济信息中心能环部主任黄炜、浙能碳资产公司副总经理吴楠、菜鸟ESG副总裁牛智敬菜鸟绿色物流总经理王昊苏等出席活动。北京汇众利合信息技术咨询有限公司作为消费侧碳中和的发起者和倡议者，以实际行动践行国家碳普惠的推广和宣传。',
          content2:'现场图片',
          content3: '',
          content4: '',
          img1: require(`@/assets/images/news/5-1.jpg`),
          img2: require(`@/assets/images/news/5-1.jpg`),
          img3: require(`@/assets/images/news/5-2.jpg`),
        },{
          sn:3,
          showSn:'03',
          groupId:1,
          path:'NewsDetail',
          yearMonth: '2022-09',
          day:'25',
          title:'CNB（碳中盒）系统助力航空公司实现“零碳飞”',
          subtitle:'2022年9月25日，首都大兴机场转场3周年庆典，北京汇众利合信息技术咨询有限公司协同首都航空启用市场机制，鼓励乘机旅客主动参与“首航零碳飞、让地球更绿色”的碳中和活动。助力首都航空成为业界第一家通过市场营销实现航班燃油碳中和的航司，并获得了由中国国检测试控股集团和北京绿色交易所共同颁发的碳中和证书。',
          content: '2022年9月25日，首都大兴机场转场3周年庆典，北京汇众利合信息技术咨询有限公司协同首都航空启用市场机制，鼓励乘机旅客主动参与“首航零碳飞、让地球更绿色”的碳中和活动。助力首都航空成为业界第一家通过市场营销实现航班燃油碳中和的航司，并获得了由中国国检测试控股集团和北京绿色交易所共同颁发的碳中和证书。',
          content2: '碳中和证书',
          content3: '首航零碳飞宣传海报',
          content4: '机上发放零碳水',
          img1: require(`@/assets/images/news/3-1.jpg`),
          img2: require(`@/assets/images/news/3-1.jpg`),
          img3: require(`@/assets/images/news/3-2.jpg`),
          img4: require(`@/assets/images/news/3-3.jpg`)
        },{
          sn:4,
          showSn:'02',
          groupId:1,
          path:'NewsDetail',
          yearMonth: '2022-07',
          day:'01',
          title:'武夷山零碳矿泉水--具有可数字化追溯的零碳商品',
          subtitle:'2022年7月，北京汇众利合信息技术咨询有限公司联合中国国检测试控股集团，通过CNB（碳中盒）系统成功为中国石油昆仑好客公司打造行业首款可追溯的零碳商品—武夷山零碳矿泉水，并实现单瓶可数字化追溯的批量化定制生产，满足政府/企业对零碳商品的需求。',
          content:' 2022年7月，北京汇众利合信息技术咨询有限公司联合中国国检测试控股集团，通过CNB（碳中盒）系统成功为中国石油昆仑好客公司打造行业首款可追溯的零碳商品—武夷山零碳矿泉水，并实现单瓶可数字化追溯的批量化定制生产，满足政府/企业对零碳商品的需求。',
          content2:'碳中和证书',
          content3: '',
          img1: require(`@/assets/images/news/2-1.jpg`),
          img2: require(`@/assets/images/news/2-1.jpg`),
          img3:''
        },
        {
          sn:5,
          showSn:'01',
          groupId:1,
          path:'NewsDetail',
          yearMonth: '2022-01',
          day:'17',
          title:'全球第一张个人碳中和电子证书诞生',
          subtitle:'2022年1月17日 ，北京汇众利合信息技术咨询有限公司联合法国必维集团在中国的全资子公司必维认证（北京）有限公司发出全球第一张在消费侧燃油出行领域的个人碳中和电子证书。该证书通过CNB（碳中盒）系统为燃油车主参加中国石油浙江嘉兴公司举办的“助力碳中和 ，让地球更绿色”的活动提供碳中和核查声明 。',
          // img1: require(`@/assets/images/chuXing/image005.png`),
          content:'2022年1月17日 ，北京汇众利合信息技术咨询有限公司联合法国必维集团在中国的全资子公司必维认证（北京）有限公司发出全球第一张在消费侧燃油出行领域的个人碳中和电子证书。该证书通过CNB（碳中盒）系统为燃油车主参加中国石油浙江嘉兴公司举办的“助力碳中和 ，让地球更绿色”的活动提供碳中和核查声明 。',
          content2:'碳中和证书',
          content3: '',
          img1: require(`@/assets/images/news/1-1.jpg`),
          img2:require(`@/assets/images/news/1-1.jpg`),
          img3:''
        }/*,{
          path:'NewsDetail',
          yearMonth: '2023-2',
          day:'25',
          title:'参加第二界中国数字碳中和高峰论坛',
          subtitle:'2023年2月25日，以“数字引领 绿色发展”为主题的第二界中国数字碳中和高峰论坛在四川成都拉开帷幕。本届论坛聚焦数字赋能“双碳”目\n' +
              '标的前沿趋势和创新路线。.......',
          content:'',
          content2:'',
          content3: '',
          content4: '',
          img1: require(`@/assets/images/news/4-1.jpg`),
          img2: null,
          img3: null
        }
        ,{
          path:'NewsDetail',
          yearMonth: '2022-11',
          day:'22',
          title:'国检研讨会',
          subtitle:'2022年11月22日，中国国检测集团、数智公司、北京汇众利合就消费侧碳中和业务进行深度探讨。'
          ,
          content:'',
          content2:'',
          content3: '',
          content4: '',
          img1: require(`@/assets/images/news/4-1.jpg`),
          img2: null,
          img3: null
        }
        ,{
          path:'NewsDetail',
          yearMonth: '2023-07',
          day:'27',
          title:'长庆油田碳中和项目交流',
          subtitle:'2023年7月27日，中国国检测试控股集团公司、西南石油大学来访采油二厂，双方召开“运用科技+市场双轮驱动助力长庆油田第二采油厂绿色低碳发展”交流会。'
          ,
          content:'',
          content2:'',
          content3: '',
          content4: '',
          img1: require(`@/assets/images/news/4-1.jpg`),
          img2: null,
          img3: null
        }
        ,{
          path:'NewsDetail',
          yearMonth: '2023-10',
          day:'10',
          title:'清华大学课题研究',
          subtitle:'2023年10月10号，清华大学与北京汇众利合就交通出行领域的消费侧化石能源、电力（燃油车、电动车）碳足迹量化、评价与认证关键技术研究课题达成共识，并确定此领域的合作内容。'
          ,
          content:'',
          content2:'',
          content3: '',
          content4: '',
          img1: require(`@/assets/images/news/4-1.jpg`),
          img2: null,
          img3: null
        }
        ,{
          path:'NewsDetail',
          yearMonth: '2023-10',
          day:'21',
          title:'中国建设银行合作',
          subtitle:'2023年10月21号，联合国检集团、建设银行，开展燃油碳中和活动收入资金（简称活动资金）用于支持约定的低碳/减碳项目发展、以及双方同意开展的低碳/减碳课题的研究投入。通过开设专......'
          ,
          content:'',
          content2:'',
          content3: '',
          content4: '',
          img1: require(`@/assets/images/news/4-1.jpg`),
          img2: null,
          img3: null
        }
        ,{
          path:'NewsDetail',
          yearMonth: '2023-12',
          day:'1',
          title:'参加内蒙销售公司阿米巴经营工作会议',
          subtitle:'2023年12月1号，受邀参加由内蒙古中石油销售分公司主持的《召开深化推进阿米巴经营工作视频会议》，就消费侧燃油碳中和如何赋能加油站绿色发展，明确双碳目标实现的过程就是油站绿色.......'
          ,
          content:'',
          content2:'',
          content3: '',
          content4: '',
          img1: require(`@/assets/images/news/4-1.jpg`),
          img2: null,
          img3: null
        }*/
      ],
      homepageIcon: '',
      AboutImg1: '',
      AboutImg2: '',
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
      sliderData: [
        {
          image: 'banner1',
          title: ``,
          description: ``
        },
        {
          image: 'banner2',
          title: ``,
          description: ``
        }
      ]

    }
  },
  created() {
    localStorage.setItem('newsDataList', JSON.stringify(this.newsDataList));
    this.getImagePath();
  },
  methods: {
    getImagePath() {
      this.homepageIcon = require(`@/assets/images/icons/home.png`)
      this.AboutImg1 = require(`@/assets/images/about/AboutImg1.png`)
      this.AboutImg2 = require(`@/assets/images/about/AboutImg2.png`)
    }
  }
}
</script>
<style scoped>


.breadcrumb-inner ul.page-list li::after {
  position: absolute;
  content: ">";
  top: 0;
  transform: translateY(5%);
  background-color: var(--color-body);
  width: 0px;
  height: 5px;
  border-radius: 1%;
  opacity: inherit;
  right: 3px;
}
.textSytle{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
