<template>
    <div class="row row--12 service-wrapper" style="text-align: center;">
<!--      style="width: 18%;margin-left: 128px;"-->
        <div class="col-lg-4 col-md-6 col-sm-6 col-12 "
             v-for="(service, index) in serviceList"
             :key=index  >
            <div data-aos="fade-up" data-aos-delay="70" style="background-color: #FFFFFF;width: 317px;height: 505px;">
                <div :class="`service ${serviceStyle} ${checkTextAlign}`">
                    <div class="inner">
                        <div class="image" >
                            <img :src="service.image" alt="Service Images" style="margin-top: 69px;" />
                        </div>
                        <div class="content" style="margin-top: 60px;">
                            <h4 class="title">
<!--                                <router-link to="/service-details" v-html="service.title"/>-->
                                <div  v-html="service.title" style="color: #30333A;font-size: 30.46px;"/>
                            </h4>
                            <p class="description b1 color-gray mb--0" v-html="service.description" style="font-size: 20px;color: #898D90;text-align: left;"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServiceThree',
        props: {
            serviceStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        image: require('@/assets/images/service/chuXingLingYu.png'),
                        title: '出行领域',
                        description: '为消费侧化石能源出行赋能城市交通的绿色发展提供解决方案'

                    },
                    {
                        image: require('@/assets/images/service/hangkong.png'),
                        title: '航空领域',
                        description: '为民用航空的消费者提供个人燃油碳中和解决方案'
                    },
                    {
                        image: require('@/assets/images/service/ditan.png'),
                        title: '零碳商品',
                        description: '为日用消费品的零碳化提供B2C的解决方案'
                    }
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>
