<template>
    <div class="logo" >
        <router-link :to="url">
            <img class="logo-light" :src="lightLogo" alt="Corporate Logo" style="min-width: 267px;min-height: 92px;"/>
            <img class="logo-dark" :src="darkLogo" alt="Corporate Logo" style="min-width: 267px;min-height: 92px;"/>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Logo2',
        props: {
            url: {
                type: String,
                default: '/'
            },
            lightLogo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/logo2.png`)
                }
            },
            darkLogo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/logo2.png`)
                    // return require(`@/assets/images/logo/logo-dark.png`)
                }
            }
        }
    }
</script>
