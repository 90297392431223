<template>
    <Layout :header-transparency="false" :show-top-header="false">
<!--         Start Slider Area banner-->
<!--        <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
&lt;!&ndash;               height-600  bg-overlay 覆盖top&ndash;&gt;
                    <div class="  bg_image">

                        <div class="container">
                            <div class="row row&#45;&#45;30 align-items-center">
                                <div class="col-lg-12">
                                    <div class="inner text-center" :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`,'background-size':'contain','background-repeat':'no-repeat','padding-top':'410px'}" style="background-size: contain;background-repeat: no-repeat;padding-top: 410px;">
                                        <h1 class="title" v-html="slider.title"/>
                                        <p class="description" v-html="slider.description"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>-->
<!--         End Slider Area-->
      <div class="blog-area">
        <div class="" style="text-align: center;">
          <div class="col-lg-12" >
            <img  :src="imgBanner" />
          </div>
        </div>
      </div>
      <!-- Start Blog Area -->
<!--      rn-section-gap  间隔-->
<!--   ,'background-position-y':'center'   'padding-top':'0px'-->
      <div class="blog-area"  :style="{'background-image': `url(${require(`@/assets/images/bg/jiejue_bj.png`)})`,'min-height':'870px'}" data-aos="fade-up">
        <div class="container">
          <div class="row">
            <div class="col-lg-12" style="margin-top: 99px;">
              <!--      subtitle            彩色小标题-->
              <SectionTitle
                  text-align="center"
                  subtitle="SOLUTION PROVIDER"
                  title="解决方案"
                  description="SOLUTION PROVIDER"
                  data-aos="fade-up"
              />
            </div>
          </div>
          <div class="row row--12" style="padding: 0px 83px 0px 166px;">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <ServiceThree
                      service-style="service__style--2"
                      text-align="center"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- End Blog Area -->



      <!-- Start Brand Area -->

      <div ref="hzhbTarget" class="blog-area"   style="margin-top: 168px;margin-bottom: 101px;" data-aos="fade-up">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <!--      subtitle            彩色小标题-->
              <SectionTitleHZHB
                  text-align="center"
                  subtitle="EOOPERATIVE PARTNER"
                  title="合作伙伴"
                  description="EOOPERATIVE PARTNER"
                  data-aos="fade-up"
              />
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
<!--                style="box-shadow: 0px 0px 3px #000;max-width: 1043px;max-height: 361px;text-align: center;" 阴影-->
               <div class="container" >
                 <Brand :brand-list="brandList" :brand-style="2" />
                 <Brand :brand-list="brandList2" :brand-style="2" />
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>


<!--      <div class="rwt-brand-area ptb&#45;&#45;90">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <Brand :brand-list="brandList" :brand-style="3"/>
            </div>
          </div>
        </div>
      </div>-->
      <!-- End Brand Area -->

        <Separator/>




    </Layout>
</template>

<script>
    import Layout from "../../components/common/Layout";
    import Separator from "../../components/elements/separator/Separator";
    import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
    import SectionTitleHZHB from "../../components/elements/sectionTitle/SectionTitleHZHB";
    import BlogPost from "../../components/blog/BlogPost";
    import BlogPostMixin from "../../mixins/BlogPostMixin";
    import VueSlickCarousel from "vue-slick-carousel";
    import Portfolio from "../../components/elements/portfolio/Portfolio";
    import Progressbar from "../../components/elements/progressbar/Progressbar";
    import Timeline from "../../components/elements/timeline/Timeline";
    import ServiceFour from "../../components/elements/service/ServiceFour";
    import Banner from "@/components/elements/testimonial/Banner";
    import Brand from "@/components/elements/brand/Brand";
    import Split from "@/components/elements/split/Split";
    import ServiceThree from "@/components/elements/service/ServiceThree";

    export default {
        name: 'Startup',
        components: {
            ServiceFour, Banner, Brand,ServiceThree,SectionTitleHZHB,
            Timeline, Progressbar, Portfolio, BlogPost, SectionTitle, Separator, Layout, VueSlickCarousel},
        mixins: [BlogPostMixin],
        data() {
            return {
                imgBanner: require(`@/assets/images/banner/banner1.png`),
                sliderSetting: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        image: 'banner1',
                        title: ``,
                        description: ``
                    },
                    {
                        image: 'banner2',
                        title: ``,
                        description: ``
                    }
                ],
              brandList: [
                {
                  image: require(`@/assets/images/brand/jiaoyisuo.png`),
                  marginleft:'0px',
                  width: '127px',
                  height:'127px'
                },
                {
                  image: require(`@/assets/images/brand/shiyou.png`),
                  marginleft:'0px',
                  width: '216px',
                  height:'142px'
                },
                {
                  image: require(`@/assets/images/brand/qighua.png`),
                  marginleft:'0px',
                  width: '120px',
                  height:'120px'
                },
                {
                  image: require(`@/assets/images/brand/xinan.png`),
                  marginleft:'45px',
                  width: '165px',
                  height:'160px'
                }

              ],
              brandList2: [
                {
                  image: require(`@/assets/images/brand/guojian.png`),
                  marginleft:'20px',
                  width: '157px',
                  height:'133px'
                },
                {
                  image: require(`@/assets/images/brand/jianhang.png`),
                  marginleft:'0px',
                  width: '217px',
                  height:'153px'
                },
                {
                  image: require(`@/assets/images/brand/hangkong.png`),
                  marginleft:'0px',
                  width: '272px',
                  height:'112px'
                }

              ],
                progressData: [
                    {
                        id: 1,
                        title: 'Design',
                        percentage: 85,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 2,
                        title: 'Photoshop',
                        percentage: 60,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 3,
                        title: "Application",
                        percentage: 70,
                        subtitle: "Presentation your skill",
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 4,
                        title: 'Development',
                        percentage: 95,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                ],
                timelineData: [
                    {
                        id: '1',
                        title: 'Knowledge',
                        description: 'Present all available features in Essentials.',
                    },
                    {
                        id: '2',
                        title: 'Working',
                        description: 'All Feature available features in Essentials.',
                    },
                    {
                        id: '3',
                        title: 'Solution',
                        description: 'Popular Feature available features in Essentials.',
                    },
                    {
                        id: '4',
                        title: 'Process',
                        description: 'Latest Feature available features in Essentials.',
                    },
                ]
            }
        },
        created() {
        },
        mounted() {
          var target = this.$route.query.target
          if(target == 2) this.scrollToTarget()
        },
        methods: {
            scrollToTarget() {
              this.$refs.hzhbTarget.scrollIntoView({ behavior: 'smooth' })
            }

          }
      }
</script>
