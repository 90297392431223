<template>
  <Layout :header-transparency="false" :show-top-header="false">
    <!--         Start Slider Area banner-->
<!--    <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
      <VueSlickCarousel v-bind="sliderSetting">
        <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
          &lt;!&ndash;               height-600  bg-overlay 覆盖top&ndash;&gt;
          <div class="  bg_image">
            &lt;!&ndash;                         :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`}"&ndash;&gt;

            <div class="container">
              <div class="row row&#45;&#45;30 align-items-center">
                <div class="col-lg-12">
                  <div class="inner text-center" :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.png`)})`,'background-size':'contain','background-repeat':'no-repeat','padding-top':'410px'}" style="background-size: contain;background-repeat: no-repeat;padding-top: 410px;">
                    <h1 class="title" v-html="slider.title"/>
                    <p class="description" v-html="slider.description"/>
                    &lt;!&ndash;                                        <div class="button-group mt&#45;&#45;30">
                                                                <a class="btn-default btn-large round"
                                                                   href="#">
                                                                    Banner页面
                                                                </a>
                                                            </div>&ndash;&gt;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>-->
    <div class="blog-area">
      <div class="" style="text-align: center;">
        <div class="col-lg-12" >
          <img  :src="require(`@/assets/images/banner/top.png`)" />
        </div>
      </div>
    </div>
    <!--         End Slider Area-->
<!--    <div class="breadcrumb-area " data-aos="fade-up" >
      <div class="container">
        <div class="row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <div class="breadcrumb-inner" style="text-align: left">
              <ul class="page-list" data-aos-delay="50">
                <router-link to="/"><img :src="homepageIcon" style="position: fixed;bottom: 4px; color: white;" /></router-link>
                <li class="rn-breadcrumb-item"><router-link to="/">   &nbsp;  &nbsp;&nbsp;&nbsp;首页</router-link></li>
                <li class="rn-breadcrumb-item" ><router-link to="/SchemeApplication">方案应用</router-link></li>
                <li class="rn-breadcrumb-item active" style="font-size: 18px;">{{pageTitle}}</li>
              </ul>
            </div>
            <Separator/>
          </div>
        </div>
      </div>
    </div>-->

    <div class="breadcrumb-area " data-aos="fade-up" >

      <div class="container">

        <div class="row">

          <div class="col-lg-1"></div>

          <div class="col-lg-10">
            <div class="breadcrumb-inner" style="text-align: left">
              <!--                <h1 class="title theme-gradient h2" v-html="title" data-aos="fade-up"></h1>-->
              <ul class="page-list" data-aos-delay="50">
                <!--                  <img :src="homepageIcon" style="position: fixed;bottom: 4px; color: white; width: 34px;height: 34px;" />-->
                <router-link to="/"><img :src="homepageIcon" style="min-width: 34px;margin-top: -14px;" /></router-link>
                <li class="rn-breadcrumb-item" style="color: #898D90;font-size: 24px;"><router-link to="/"> &nbsp;首页</router-link></li>
                <li class="rn-breadcrumb-item " style="font-size: 24px;color: #898D90;opacity: 1;">&nbsp;<router-link to="/SchemeApplication">方案应用</router-link></li>
                <li class="rn-breadcrumb-item active" style="font-size: 30px;color: #444444;opacity: 1;">&nbsp;{{pageTitle}}</li>

              </ul>
            </div>
            <Separator style="margin-top: 10px;"/>
          </div>
        </div>
      </div>
    </div>


    <!--      rn-section-gap  间隔-->
    <!--      :style="{'background-image': `url(${require(`@/assets/images/bg/jiejue_bj.png`)})`,'background-size':'contain','background-repeat':'no-repeat','padding-top':'0px','background-position-x':'center','background-position-y':'center'}"-->
    <div class="blog-area"  style="margin-top: 68px;" data-aos="fade-up" >
      <div class="container">
        <div class="row" style="margin-top: 30px;margin-left: 20px;" v-for="(item, index) in chuXingDataList" v-if="groupId == item.groupId">

            <div class="col-lg-1"></div>

            <div class="col-lg-11" >
              <div class="row">
                <div class="col-lg-4" align="center" style="background-color: #22B274;width: 248px;height: 165px;text-align: center;" >
<!--                  max-height: 165px;max-width: 248px;-->
<!--                  <img :src="item.img1"  style=" margin-top: 10px;max-height: 248px;max-width: 248px;"  />-->
                  <p style="font-size: 100px; font-weight: bold;color: white;">{{item.sn}}</p>

                </div>
                <div class="col-lg-8">
                  <font class="title " style="font-size: 18px;color: #0E3F6F;">{{ item.titleOut }}</font>
                    <div style="font-size: 12px;color: #969696">{{item.startDate}}</div>
                  <div class="description b1 textSytle" style="font-size: 14px;color: #646464;height: 70px;"  >{{item.subTitle}}</div>
                  <div style="margin-top: 10px;">
                    <Button class="button-3" :url="{path:'/chuXingDetail',query:{groupId:groupId,sn:item.sn-1}}" title="查看详情"   />

                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-2"></div>
        </div>

      </div>
    </div>
    <!-- End Blog Area -->

  <div style="margin-top: 68px;">
    <div class="container">

      <div class="row">
        <div class="col-lg-1">

        </div>
        <div class="col-lg-11">
         <div class="row">
           <div class="col-lg-2">
           </div>
           <div class="col-lg-3">
             <div style="font-size: 12px;color:#646464;padding-top: 10px;">共1页  共{{ pageTotol }}条信息</div>
           </div>
           <div class="col-lg-7">
             <div class="source" >
               <div style="width: 465px;height: 36px;margin-left: 150px;">
                 <div class="el-pagination is-background">
                   <button type="button" disabled="disabled" class="btn-prev" style="height: 36px;width:90px;">
                     首页
                   </button>
                   <ul class="el-pager" >
                     <li class="number active" style="min-height:36px;min-width:36px;font-size: 12px;padding: 5px;background-color: #00C390">1</li><!---->
<!--                     <li class="number" style="min-height:36px;min-width:36px;font-size: 12px;padding: 8px;">2</li>
                     <li class="number" style="min-height:36px;min-width:36px;font-size: 12px;padding: 8px;">3</li>
                     <li class="number" style="min-height:36px;min-width:36px;font-size: 12px;padding: 8px;">4</li>
                     <li class="number" style="min-height:36px;min-width:36px;font-size: 12px;padding: 8px;">5</li>
                     <li class="number" style="min-height:36px;min-width:36px;font-size: 12px;padding: 8px;">6</li>-->
<!--                  省略号   <li class="el-icon more btn-quicknext el-icon-more"></li>-->
                   </ul>
                   <button type="button" disabled="disabled" class="btn-next" style="height: 36px;width:90px;">
                     下一页
                   </button>
                 </div>
               </div>
             </div>
           </div>
         </div>
        </div>
      </div>
    </div>

  </div>

    <div style="margin-top: 58px;">
    </div>

  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";
import VueSlickCarousel from "vue-slick-carousel";
import Separator from "@/components/elements/separator/Separator";
import ServiceThreeSchemeApplication from "@/components/elements/service/ServiceThreeSchemeApplication";
import Button from "@/components/elements/button/Button";

export default {
  name: 'ChuXing',
  components: {Button, ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout,VueSlickCarousel,Separator,ServiceThreeSchemeApplication},
  data() {
    return {
      groupId:1,
      pageTotol:0,
      pageTitle:'陆路出行',
      chuXingDataList: [
        {
          sn:2,
          groupId:1,
          path:'/ChuXingDetail',
          startDate:'2022年11月4日',
          issuer: '',
          titleOut:'中国石油北京销售“助力碳中和、让地球更绿色”',
          titleIn:'助力碳中和、让地球更绿色',
          subTitle:'鉴于中石油浙江销售参与的燃油碳中和试点项目的成功，以及引起的社会反响。中国石油销售公司推荐北京销售公司在中油好客e站（APP）开展北京地区所有加油站的燃油碳中和活动。目的是通过在中油好客ｅ站（APP）未来开展全域碳中和活动，以实际行动践行中国石油销售企业的双碳目标，履行企业的社会责任。',
          // subTitle:'2022年1月17日 ，北京汇众利合在浙江嘉兴开展了一次“低碳消费 ，让地球更绿色”的试点活动 ，通过自主研发的消费端互联网碳普惠工具  (碳中盒) 对随机车主开展活动 ，帮助300名车主实现碳中和 ，并发放由必维认证(北京)有限公司提供的电子核查声明证书。',
          content:'11月4日，以“助力碳中和 让地球更绿色”为主题的碳中和消费服务活动在北京地区试点上线，标志着中国石油成为行业内首家为车主用户提供化石能源个人消费领域碳中和服务的成品油零售企业。\n' +
              '　　随着我国“双碳”目标的提出和深入推进，按照集团公司“清洁替代、战略接替、绿色转型”三步走总体部署，中国石油销售公司不断加快绿色低碳转型发展步伐，大力发展新能源业务，积极构建多能互补新格局。此次试点活动的推出，是以实际行动学习贯彻落实党的二十大精神、持续推进销售业务高质量转型发展的重要举措。\n' +
              '　　试点活动时间为2022年11月4日至30日，中国石油在北京地区注册用户线上可通过登录“中油好客e站”APP参与活动，线下可到北京销售公司南湖、安燕、月坛、亦庄、科大、金龙等加油站点现场参与，活动参与用户上限为1万人。\n' +
              '　　活动期间，中国石油注册消费者可在其最近的6笔汽油加油历史订单中，自主选择其中一笔订单参与活动，并完成相应碳中和费用支付。支付完成后，消费者可在线实时获得由中国国检测试控股集团股份有限公司出具的“碳中和核查声明”证书，并可在朋友圈进行分享。同时，消费者还可获得由北京销售免费提供的电子券权益礼包。',
          img1: require(`@/assets/images/chuXing/image007.png`),
          img2: null,
          imag3: null,
        }
        ,{
          sn:1,
          groupId:1,
          path:'/ChuXingDetail',
          startDate:'2022年1月17日',
          issuer: '',
          titleOut:'全球首例消费侧燃油碳中和试点项目',
          titleIn:'消费侧燃油碳中和项目',
          subTitle:'消费侧燃油出行产生的不可避免的碳排是交通领域碳排放的主要源头，也是各国在双碳领域关注的重点问题。主要的解决手段之一就是提高燃油车主的环保意识，减少或降低燃油车使用频次。为此，对燃油车主的双碳普及尤为重要，提供更多的实际参与机会是推动双碳教育的核心。本试点项目的目的就是通过市场激励机制，在加油站让更多的燃油车主参与燃油碳中和活动，用数字化的手段建立溯源查证的机制，提升燃油车主个人社会责任意识。',
          content:'2022年1月17日 ，北京汇众利合信息技术咨询有限公司联合中国石油浙江销售公司在浙江嘉兴的加油站开展了行业内首次“燃油碳中和 ，让地球更绿色”的碳中和活动 ，通过北京汇众利合自主研发的消费端数字化碳中和工具CNB (碳中盒) 对在加油站加油的车主提供燃油碳中和服务 ，5天时间超过300名车主主动自愿参加活动，必维认证(北京)有限公司为参与活动的车主提供实时 “碳中和核查声明” 电子证书，活动效果超出预期，社会反响强烈。',
          img1: require(`@/assets/images/chuXing/image005.png`),
          img2: null,
          imag3: null,
        }
       ,{
          sn:1,
          groupId:2,
          path:'/ChuXingDetail',
          startDate:'2022年9月25日',
          issuer: '',
          titleOut:'首航零碳飞 让地球更绿色',
          titleIn:'首航零碳飞 让地球更绿色',
          subTitle:'运用数字化手段，启用市场机制鼓励乘客主动自愿参加燃油碳中和活动，个人行为支持民用航空的绿色飞行',
          content:'2022年9月25日在北京大兴机场3周年庆典活动中，以“925大兴机场节”命名的由杭州萧山机场飞往北京大兴机场JD5362航班，开展了”首航零碳飞、让地球更绿色“的主题活动，由首航乘客自主参与所乘坐航班燃油碳中和活动；首都航空鼓励每一位乘客通过北京汇众利合提供的燃油碳中和服务，实现本人乘机燃油碳排放的碳抵消。由此获得中国国检集团颁发的个人碳中和电子证书，用以证明和鼓励消费者的碳中和行为。大兴机场节活动，开创了首都航空成为业内第一家通过营销实现航班碳中和的航司。',
          content2:'',
          content3:'',
          content4:'',
          content5:'',
          img1: require(`@/assets/images/chuXing/hk1.png`),
        },{
          sn:1,
          groupId:3,
          path:'/ChuXingDetail',
          startDate:'2022年7月5日',
          issuer: '',
          titleOut:'中国石油昆仑好客公司武夷山零碳矿泉水',
          titleIn:'中国石油首创“零碳”武夷山矿泉水',
          subTitle:'通过打造零碳商品助力碳普惠的推广。',
          content:'2022年7月，北京汇众利合信息技术咨询有限公司联合中国石油昆仑好客公司打造了第一款“零碳”武夷山矿泉水。该产品用于浙江碳普惠的推广以及对零碳商品有需求的客户。',
          content2:'',
          content3:'',
          img1: require(`@/assets/images/chuXing/ltsp1-1.png`),
        }
      ],
      homepageIcon: '',
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
      sliderData: [
        {
          image: 'banner1',
          title: ``,
          description: ``
        },
        {
          image: 'banner2',
          title: ``,
          description: ``
        }
      ]

    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.homepageIcon = require(`@/assets/images/icons/home.png`)
      localStorage.setItem('chuXingDataList', JSON.stringify(this.chuXingDataList));
      this.groupId = this.$route.query.groupId;
      this.pageTitle = this.groupId == 1?"陆路出行":this.groupId==2?"航空出行":"零碳商品"
      var i = 0;
      for (const item of this.chuXingDataList) {
        if(item.groupId==this.groupId) i++;
      }
      this.pageTotol = i;
    }
  }
}
</script>
<style scoped>


.breadcrumb-inner ul.page-list li::after {
  position: absolute;
  content: ">";
  top: 0;
  transform: translateY(5%);
  background-color: var(--color-body);
  width: 0px;
  height: 5px;
  border-radius: 1%;
  opacity: inherit;
  right: 3px;
}

.textSytle{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}


.button-3 {
  width:100px;
  height: 30px !important;
  appearance: none;
  background-color: #fff;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 0px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 15px;
  /*font-weight: 600;*/
  line-height: 20px;
  padding: 4px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #07a97f;
}

.button-3:focus {
  box-shadow: #00C390;
  outline: none;
}

.button-3:disabled {
  background-color: #00C390;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-3:active {
  background-color:#00C390;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

</style>
