<template>
    <div style="padding-top: 44px;" >
<!--      footer-style-default  footer-style-1-->
        <footer v-if="data.showFooter" class="rn-footer " >
            <div class="footer-top"  style="height: 296px;" >
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div class="rn-footer-widget" style="top: 60px;position: relative;">
                                <Logo2/>
<!--                                <h3 class="text-big">Create Website By Doob So Quick Download And Make Your Site.</h3>-->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div class="">
                                <div class="widget-menu-top">
                                    <h4 class="title" style="font-size: 18px;color: #FFFFFF;"> &nbsp;&nbsp;   &nbsp;&nbsp;联系我们</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover" >
                                            <li >客服电话：010-82062615</li>
                                            <li>商业沟通：miaolei@mycnb.cn </li>
                                            <li>办公时间：工作日9:00-18:00</li>
                                            <li>客服电话：010- 82062615</li>
                                            <li>办公地址：北京市朝阳区水岸南街中合国青大厦6层</li>
<!--                                            <li><router-link to="/testimonial">Testimonial</router-link></li>
                                            <li><router-link to="/company">Company</router-link></li>
                                            <li><router-link to="/marketing-agency">Marketing</router-link></li>
                                            <li><router-link to="/startup">Startup</router-link></li>-->
                                        </ul>
                                    </div>
                                </div>
<!--                                <div class="widget-menu-bottom">
                                    <h4 class="title">案例</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover">
                                            <li><router-link to="/about">About</router-link></li>
                                            <li><router-link to="/portfolio">Portfolio</router-link></li>
                                            <li><router-link to="/contact">Contact</router-link></li>
                                            <li><router-link to="/service">Service</router-link></li>
                                        </ul>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <div class="widget-menu-top">
                                    <h4 class="title"  style="font-size: 18px;color: #FFFFFF;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;案例</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover">
                                            <li><router-link to="/SchemeApplication"><font class="fontcolor">查看案例</font></router-link></li>
                                            <li><router-link to="/Startup?target=2"><font>合作伙伴</font></router-link></li>
                                            <li><router-link to="/SchemeApplication"><font>解决方案</font></router-link></li>
                                        </ul>
                                    </div>
                                </div>
<!--                                <div class="widget-menu-bottom">
                                    <h4 class="title">Solutions</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover">
                                            <li><router-link to="/gallery">Gallery</router-link></li>
                                            <li><router-link to="/testimonial">Testimonial</router-link></li>
                                            <li><router-link to="/contact">Contact</router-link></li>
                                            <li><router-link to="/service">Service</router-link></li>
                                        </ul>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                            <div class="rn-footer-widget">
                              <div class="widget-menu-top">
                                <h4 class="title"  style="font-size: 18px;color: #FFFFFF;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;关于</h4>
                                <div class="inner">
                                  <ul class="footer-link link-hover">
                                    <li><router-link to="/about"><font>关于我们</font></router-link></li>
                                    <li><router-link to="/news"><font>新闻资讯</font></router-link></li>
                                    <li > <a @click="scrollToTop"><font>返回顶部</font></a></li>
                                  </ul>
                                </div>
                              </div>
<!--                                <h4 class="title">关于</h4>
                                <div class="inner">
                                    <h6 class="subtitle">2000+ Our clients are subscribe Around the World</h6>
                                    <form class="newsletter-form" action="#">
                                        <div class="form-group">
                                            <input type="email" placeholder="Enter Your Email Here">
                                        </div>
                                        <div class="form-group">
                                            <button class="btn-default" type="submit">Submit Now</button>
                                        </div>
                                    </form>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
              <Copyright v-if="data.showCopyright"/>

            </div>

        </footer>

      <ScrollTop/>
    </div>
</template>

<script>
    import Copyright from './Copyright'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'
    import Logo2 from "@/components/elements/logo/Logo2";

    export default {
      name: 'FooterThree',
      components: {Logo2, ScrollTop, Copyright},
      props: {
        data: {
          default: null
        }
      },
      data() {
        return {
          visible: false
        }
      },
      methods: {
        scrollToTop() {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      }
    }
</script>
<style scoped>
  .title {
    color: rgb(255,255,255);
  }
  body.active-light-mode ul li{
    color: #fff;
    font-siz:14px;
  }
  body.active-light-mode ul a{
    color: #fff;
  }

  ul li a:hover {
    color: var(--color-primary);
  }
  ul li {
    /*font-size: var(--font-size-b1);*/
    line-height: var(--line-height-b1);
    margin-top: 10px;
    margin-bottom: 10px;
    color: #FFFFFF;
    /*font-weight: 400;*/
    font-size: 14px;
    list-style: none;
  }
</style>
